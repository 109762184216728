import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import {
  brandColours,
  fontSize,
  fontWeights,
  minBreakpointQuery,
  sectionPaddings,
} from '../styles';
import { Container, HighlightText, Svg } from './ui';
import triangle from '../images/triangle.inline.svg';
import hexagon from '../images/hexagon.inline.svg';
import trapezium from '../images/trapezium.inline.svg';
import paperGrid from '../images/paper-tear.png';

const StyledContentCards = styled.section`
  ${sectionPaddings('100px', '110px')}
  background-color: ${brandColours.quinary};
  position: relative;
`;

const StyledHexagon = styled(Svg)`
  position: absolute;
  bottom: -70px;
  right: 50px;
  z-index: 1;
  fill: ${brandColours.senary};
`;

const StyledInner = styled.div`
  display: grid;
  justify-items: center;
  margin-right: auto;
  margin-left: auto;
  gap: 40px;

  ${minBreakpointQuery.medium`
    align-items: center;
  `}

  ${minBreakpointQuery.large`
    grid-template-columns: repeat(3,1fr);
    align-items: unset;
    justify-items: unset;
    gap: 0;
  `}
`;

const StyledItem = styled.div`
  ${minBreakpointQuery.medium`
    display: grid;
    grid-template-columns: 400px 1fr
  `}

  ${minBreakpointQuery.large`
    display: block;
  `}
`;

const StyledImageWrapper = styled.div`
  position: relative;
`;

const StyledBackground = styled.div`
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  position: absolute;
  left: -15px;
  width: 100%;
  height: calc(100% + 50px);
  -webkit-background-size: 700px auto;
  background-size: 700px auto;
  background-repeat: no-repeat;
  -webkit-background-position: 50% bottom;
  background-position: 50% bottom;
  background-image: url(${paperGrid});

  ${({ i }) => {
    if (i === 0) {
      return css`
        z-index: 3;

        ${minBreakpointQuery.large`
          width: 120%;
          margin-top: 20px;
          height: calc(100% + 30px);
        `}
      `;
    } else {
      return css`
        transform: rotate(180deg);
        top: -50px;
      `;
    }
  }}
`;

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;

  ${({ i }) => {
    if (i === 0) {
      return css`
        transform: rotate(-1.5deg);
        z-index: 4;
      `;
    } else if (i === 1) {
      return css`
        transform: rotate(1.5deg);
        z-index: 2;
        ${minBreakpointQuery.large`
          margin-left: -20px;
          margin-top: 20px;
        `}
      `;
    } else if (i === 2) {
      return css`
        transform: rotate(-1.5deg);

        ${minBreakpointQuery.large`
          margin-left: -40px;
        `}
      `;
    }
  }}
`;

const StyledContent = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;

const StyledHeading = styled.h2`
  font-weight: ${fontWeights.semibold};
  ${fontSize(24)};
  color: ${brandColours.secondary};
  margin: 40px 0;

  ${minBreakpointQuery.medium`
    margin-top: 0;
    ${fontSize(28)};
  `}

  ${minBreakpointQuery.large`
    margin-top: 40px;
    ${fontSize(32)};
  `}
`;

const StyledText = styled.p`
  font-weight: ${fontWeights.light};
  white-space: break-spaces;
`;

const StyledLeftTrapezium = styled(Svg)`
  position: absolute;
  bottom: 20px;
  left: -50px;
  fill: ${brandColours.primary};
  transform: rotate(180deg);
  z-index: 5;
`;

const StyledTriangle = styled(Svg)`
  position: absolute;
  top: -45px;
  right: 40px;
  transform: rotate(100deg);
  fill: ${brandColours.quaternary};
  z-index: 5;
`;

const StyledRightTrapezium = styled(Svg)`
  position: absolute;
  bottom: -40px;
  right: 20px;
  fill: ${brandColours.tertiary};
  z-index: 5;
`;

const ContentCards = ({ items }) => (
  <StyledContentCards>
    <StyledHexagon image={hexagon} />
    <Container narrow={true}>
      <StyledInner>
        {items.map(({ heading, content, image }, i) => (
          <StyledItem key={i}>
            <StyledImageWrapper>
              {i % 2 === 0 ? (
                <StyledBackground i={i}></StyledBackground>
              ) : (
                <></>
              )}

              <StyledImage
                image={image.gatsbyImageData}
                alt={image.title}
                i={i}
              />
              {i === 0 && <StyledLeftTrapezium image={trapezium} />}
              {i === 1 && <StyledTriangle image={triangle} />}
              {i === 2 && <StyledRightTrapezium image={trapezium} />}
            </StyledImageWrapper>
            <StyledContent>
              <StyledHeading id={i}>
                <HighlightText offset={i + 2}>{heading}</HighlightText>
              </StyledHeading>
              <StyledText>{content.content}</StyledText>
            </StyledContent>
          </StyledItem>
        ))}
      </StyledInner>
    </Container>
  </StyledContentCards>
);
export default ContentCards;
