import React from 'react';
import styled from 'styled-components';
import { brandColours, fontSize, standardColours } from '../styles';
import { Button, Svg } from './ui';
import twitterIcon from '../images/twitter-icon.inline.svg';

const StyledTwitterCard = styled.article`
  position: relative;
`;

const StyledIcon = styled(Svg)`
  position: absolute;
  top: -20px;
  left: 0;
  width: 56px;
  height: 56px;
  padding: 14px;
  background-color: ${brandColours.tertiary};
`;

const StyledContent = styled.div`
  background-color: ${brandColours.octonary};
  padding: 30px;
  border-radius: 8px;
`;

const StyledDate = styled.time`
  display: block;
  ${fontSize(15)}
  margin-top: 25px;
`;

const StyledTwitterHandle = styled.p`
  margin-top: 20px;
  margin-bottom: 5px;
`;

const StyledText = styled.p`
  margin-bottom: 5px;

  a {
    color: ${standardColours.black};

    &:hover {
      border-bottom-color: ${standardColours.black};
    }
  }
`;

const StyledButton = styled(Button)`
  margin-top: 35px;
`;

const TwitterCard = ({ id, createdDate, metaCreatedDate, text, ...props }) => (
  <StyledTwitterCard {...props}>
    <StyledIcon image={twitterIcon} />
    <StyledContent>
      <StyledDate dateTime={metaCreatedDate}>{createdDate}</StyledDate>
      <StyledTwitterHandle>
        <strong>@MinistryEco</strong>
      </StyledTwitterHandle>
      <StyledText>{text}</StyledText>
      <StyledButton
        alt={true}
        href={`https://twitter.com/MinistryEco/status/${id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Read
      </StyledButton>
    </StyledContent>
  </StyledTwitterCard>
);

export default TwitterCard;
