import React from 'react';
import { graphql } from 'gatsby';
import ContentCards from './ContentCards';
import FeaturedList from './FeaturedList';
import ImageGallery from './ImageGallery';
import LatestNews from './LatestNews';
import Resources from './Resources';

const ModularBlocks = ({ items }) =>
  items.map((item, i) => (
    <React.Fragment key={i}>
      {item.sys.contentType.sys.id === 'contentCards' && (
        <ContentCards items={item.contentCards} />
      )}
      {item.sys.contentType.sys.id === 'featuredList' && (
        <FeaturedList
          heading={item.featuredListHeading}
          description={item.description}
          image={item.image}
          list={item.list}
          text={item.text}
        />
      )}
      {item.sys.contentType.sys.id === 'imageGallery' && (
        <ImageGallery
          header={item.header}
          subHeader={item.subHeader}
          gallerySlides={item.gallerySlides}
        />
      )}
      {item.sys.contentType.sys.id === 'latestNews' && (
        <LatestNews heading={item.latestNewsHeading} />
      )}
      {item.sys.contentType.sys.id === 'resources' && (
        <Resources videos={item.videos} pdfs={item.pdfs} />
      )}
    </React.Fragment>
  ));

export default ModularBlocks;

export const ModularBlockFragments = graphql`
  fragment ContentCardsModularBlockFragment on ContentfulContentCardsBlock {
    sys {
      contentType {
        sys {
          id
        }
      }
    }
    contentCards {
      title
      heading {
        raw
      }
      content {
        content
      }
      image {
        gatsbyImageData(width: 800, height: 530)
        title
      }
    }
  }

  fragment FeaturedListModularBlockFragment on ContentfulFeaturedList {
    sys {
      contentType {
        sys {
          id
        }
      }
    }
    featuredListHeading: heading {
      raw
    }
    description {
      raw
    }
    image {
      gatsbyImageData(width: 325, height: 400)
    }
    list {
      heading
      text
    }
    text {
      raw
    }
  }

  fragment ImageGalleryModularBlockFragment on ContentfulImageGallery {
    sys {
      contentType {
        sys {
          id
        }
      }
    }
    header {
      raw
    }
    subHeader {
      raw
    }
    gallerySlides {
      slideDescription
      slideImage {
        gatsbyImageData(width: 840, height: 540)
        title
      }
    }
  }

  fragment LatestNewsModularBlockFragment on ContentfulLatestNews {
    sys {
      contentType {
        sys {
          id
        }
      }
    }
    latestNewsHeading: heading {
      raw
    }
  }

  fragment ResourcesModularBlockFragment on ContentfulResources {
    sys {
      contentType {
        sys {
          id
        }
      }
    }
    videos {
      heading {
        raw
      }
      videos {
        ...VideoFragment
      }
    }
    pdfs {
      heading {
        raw
      }
      text
      pdf {
        file {
          url
          fileName
          details {
            size
          }
        }
      }
    }
  }
`;
