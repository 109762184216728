import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ModularBlocks from '../components/ModularBlocks';

const IndexPage = () => {
  const {
    contentfulSiteData: { siteName },
    contentfulHome: {
      title,
      heading,
      bannerVideo,
      bannerPersonProfiles,
      metaDescription,
      modularBlocks,
    },
  } = useStaticQuery(graphql`
    query IndexPageQuery {
      contentfulSiteData {
        siteName
      }
      contentfulHome {
        title
        heading {
          raw
        }
        bannerVideo {
          ...VideoFragment
        }
        bannerPersonProfiles {
          image {
            gatsbyImageData(width: 390, height: 455)
            ...ImageFragment
          }
          name
          description
          twitterHandle
        }
        metaDescription
        modularBlocks {
          ...ContentCardsModularBlockFragment
          ...FeaturedListModularBlockFragment
          ...ImageGalleryModularBlockFragment
          ...LatestNewsModularBlockFragment
          ...ResourcesModularBlockFragment
        }
      }
    }
  `);

  return (
    <Layout title={title} description={metaDescription} siteName={siteName}>
      <main>
        <Banner
          heading={heading || title}
          video={bannerVideo}
          profiles={bannerPersonProfiles}
          isHome={true}
        />
        <ModularBlocks items={modularBlocks} />
      </main>
    </Layout>
  );
};

export default IndexPage;
