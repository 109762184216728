import React from 'react';
import styled, { css } from 'styled-components';
import {
  brandColours,
  breakpointSizes,
  fluidFontSize,
  fontSize,
  fontWeights,
  minBreakpointQuery,
  sectionPaddings,
} from '../styles';
import { Svg, Container, HighlightText, Video } from './ui';
import fileIcon from '../images/file-icon.inline.svg';
import arrow from '../images/arrow.inline.svg';

const StyledResources = styled.section`
  ${sectionPaddings('125px', '140px')}
`;

const StyledContainer = styled(Container)`
  position: relative;
`;

const StyledHeading = styled.h2`
  ${fluidFontSize(
    '28px',
    '42px',
    breakpointSizes.tsmall,
    breakpointSizes.medium
  )}
  margin-bottom:25px;
`;

const StyledArrow = styled(Svg)`
  display: none;
  position: absolute;
  ${({ isBottom }) => {
    if (isBottom) {
      return css`
        bottom: -200px;
        z-index: 1;

        ${minBreakpointQuery.small`
          left: 20px;
          display:block;
        `}

        ${minBreakpointQuery.medium`
          left: 25px;
        `}

        ${minBreakpointQuery.large`
          left: -70px;
        `}

        ${minBreakpointQuery.xlarge`
          left: -100px;
        `}
      `;
    } else {
      return css`
        top: -200px;
        transform: scaleX(-1);

        ${minBreakpointQuery.small`
          right: 5%;
          display:block;
        `}

        ${minBreakpointQuery.xxlarge`
          right:15%;
        `}
      `;
    }
  }}
`;

const StyledVideoContainer = styled.div`
  display: grid;
  margin-bottom: 70px;
  gap: 25px;

  ${minBreakpointQuery.smedium`
    grid-template-columns: repeat(2, 1fr);
  `}
`;

const StyledVideo = styled(Video)`
  ${({ i }) => {
    if (i % 2 === 0) {
      return css`
        transform: rotate(-1.5deg);
      `;
    } else {
      return css`
        transform: rotate(1.5deg);
      `;
    }
  }}
`;

const StyledPdfContainer = styled.div``;

const StyledSubHeading = styled.h3`
  ${fontSize(20)}
  font-weight: ${fontWeights.light};
  margin-bottom: 20px;

  ${minBreakpointQuery.small`
    ${fontSize(22)}
  `}

  ${minBreakpointQuery.smedium`
    margin-bottom: 30px;
  `}

  ${minBreakpointQuery.medium`
    ${fontSize(24)}
    margin-bottom: 40px;
  `}

  ${minBreakpointQuery.large`
    ${fontSize(26)}
  `}
`;

const StyledGrid = styled.div`
  display: grid;
  grid-row-gap: 25px;

  ${minBreakpointQuery.smedium`
    grid-template-columns: repeat(2, 1fr);
  `}
`;

const StyledLink = styled.a``;

const StyledPdf = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const StyledFile = styled(Svg)`
  flex-shrink: 0;
`;

const StyledName = styled.p`
  font-weight: ${fontWeights.semibold};
  overflow-wrap: anywhere;

  ${minBreakpointQuery.small`
    ${fontSize(18)}
  `}

  &:hover {
    color: ${brandColours.tertiary};
    text-decoration: underline;
    transition: 0.5s;
  }
`;

const StyledSize = styled.span`
  font-weight: ${fontWeights.light};
`;

const Resources = ({ videos, pdfs }) => {
  const getFileSize = (bytes, dp = 1) => {
    const thresh = 1000;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = ['kb', 'mb'];
    let unit = -1;
    const round = 10 ** dp;

    do {
      bytes /= thresh;
      ++unit;
    } while (
      Math.round(Math.abs(bytes) * round) / round >= thresh &&
      unit < units.length - 1
    );

    return bytes.toFixed(dp) + units[unit];
  };

  return (
    <StyledResources>
      <StyledContainer narrow={true}>
        <StyledHeading>
          <HighlightText>{videos.heading}</HighlightText>
        </StyledHeading>
        <StyledVideoContainer>
          {videos.videos.map((video, i) => (
            <StyledVideo
              key={i}
              {...video}
              alt={i % 2 === 0}
              hasBackground={true}
              inverted={i % 2 === 1 ? true : false}
              i={i}
            />
          ))}
        </StyledVideoContainer>
        <StyledPdfContainer>
          <StyledHeading>
            <HighlightText>{pdfs.heading}</HighlightText>
          </StyledHeading>
          <StyledSubHeading>{pdfs.text}</StyledSubHeading>
          <StyledGrid>
            {pdfs.pdf.map(({ file }, id) => (
              <StyledLink
                key={id}
                href={file.url}
                rel="noreferrer"
                target="_blank"
              >
                <StyledPdf>
                  <StyledFile image={fileIcon} />
                  <StyledName>
                    {file.fileName.split('.')[0]}
                    <StyledSize>
                      {' {' +
                        file.fileName.split('.')[1].toUpperCase() +
                        ', ' +
                        getFileSize(parseInt(file.details.size)) +
                        '}'}
                    </StyledSize>
                  </StyledName>
                </StyledPdf>
              </StyledLink>
            ))}
          </StyledGrid>
        </StyledPdfContainer>
        <StyledArrow image={arrow} isBottom={true} />
      </StyledContainer>
    </StyledResources>
  );
};

export default Resources;
