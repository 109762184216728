import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Masonry from 'react-masonry-component';
import styled from 'styled-components';
import {
  brandColours,
  fontSize,
  maxBreakpointQuery,
  minBreakpointQuery,
  sectionMargins,
} from '../styles';
import { Container, Heading, HighlightText, Link, Svg } from './ui';
import BlogCard from './BlogCard';
import TwitterCard from './TwitterCard';
import trapezium from '../images/trapezium.inline.svg';

const StyledLatestNews = styled.section`
  ${sectionMargins('70px', '120px')}
`;

const StyledContainer = styled(Container)`
  position: relative;
`;

const StyledTrapezium = styled(Svg)`
  display: none;

  ${minBreakpointQuery.small`
    display:block;
    position: absolute;
    fill: ${brandColours.quaternary};
    top: 0px;
    left: 500px;
    transform: rotate(10deg);
  `}

  ${minBreakpointQuery.xxlarge`
    top: 200px;
    left: -160px;
  `}
`;

const StyledIntro = styled.div`
  ${minBreakpointQuery.medium`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;

const StyledLinks = styled.div`
  ${maxBreakpointQuery.medium`
    margin-top: 15px;
  `}

  ${minBreakpointQuery.medium`
    display: flex;
    column-gap: 30px;
  `}

  ${minBreakpointQuery.mlarge`
    column-gap: 40px;
  `}

  ${minBreakpointQuery.large`
    column-gap: 50px;
  `}

  ${minBreakpointQuery.xlarge`
    column-gap: 60px;
  `}
`;

const StyledLink = styled(Link)`
  display: block;
  text-decoration: underline;

  ${maxBreakpointQuery.medium`
    margin: 10px 0;
  `}

  ${minBreakpointQuery.large`
    ${fontSize(18)}
  `}
`;

const StyledMasonry = styled(Masonry)`
  margin-top: 30px;

  ${minBreakpointQuery.mlarge`
    margin-top: 40px;
  `}
`;

const StyledBlogCard = styled(BlogCard)`
  margin-bottom: 20px;
  ${minBreakpointQuery.small`
    width: calc(50% - 10px);
  `}

  ${minBreakpointQuery.mlarge`
    width: calc(33% - 10px);
  `}
`;

const StyledTwitterCard = styled(TwitterCard)`
  margin-bottom: 20px;
  margin-top: 20px;

  ${minBreakpointQuery.small`
    width: calc(50% - 10px);
  `}

  ${minBreakpointQuery.mlarge`
    width: calc(33% - 10px);
  `}
`;

const LatestNews = ({ heading, items }) => {
  const {
    contentfulSocialProfiles: { twitterProfile },
    allContentfulBlog: { nodes: blogs },
  } = useStaticQuery(graphql`
    query LatestNewsQuery {
      contentfulSocialProfiles {
        twitterProfile
      }
      allContentfulBlog(limit: 3) {
        nodes {
          id
          title
          slug
          featuredImage {
            title
            gatsbyImageData(width: 620, height: 640)
          }
          createdAt(formatString: "DD MMMM YYYY")
          metaCreatedDate: createdAt(formatString: "YYYY-MM-DD")
          introText {
            introText
          }
          category {
            title
          }
        }
      }
    }
  `);

  const defaultBlogs = blogs.sort(function (a, b) {
    return new Date(b.metaCreatedDate) - new Date(a.metaCreatedDate);
  });

  const defaultItems = defaultBlogs;

  const newsItems = items ? items : defaultItems;

  return (
    <StyledLatestNews>
      <StyledContainer>
        <StyledTrapezium image={trapezium} />
        <StyledIntro>
          <Heading>
            <HighlightText offset={1}>{heading}</HighlightText>
          </Heading>
          {!items && (
            <StyledLinks>
              <StyledLink
                href={twitterProfile}
                target="_blank"
                rel="noopener noreferrer"
              >
                View tweets
              </StyledLink>
              <StyledLink to="/blog/">View blog posts</StyledLink>
            </StyledLinks>
          )}
        </StyledIntro>
        {newsItems.length > 0 && (
          <StyledMasonry
            options={{
              itemSelector: '.masonry-item',
              percentPosition: true,
              gutter: 20,
            }}
          >
            {newsItems.map(item => (
              <StyledBlogCard
                key={item.id}
                className="masonry-item"
                title={item.title}
                slug={item.slug}
                featuredImage={item.featuredImage}
                createdAt={item.createdAt}
                metaCreatedDate={item.metaCreatedDate}
                introText={item.introText}
                category={item.category}
              />
            ))}
          </StyledMasonry>
        )}
      </StyledContainer>
    </StyledLatestNews>
  );
};

export default LatestNews;
