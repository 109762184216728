import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { BLOCKS } from '@contentful/rich-text-types';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  fontSize,
  fontWeights,
  sectionPaddings,
  brandColours,
} from '../styles';
import { Svg, Container, Slider, HighlightText } from './ui';
import arrow from '../images/arrow.inline.svg';
import annotation from '../images/annotation.inline.svg';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

const StyledImageGallery = styled.section`
  ${sectionPaddings('100px', '140px')}
  position: relative;
`;

const StyledContext = styled.div`
  padding-bottom: 50px;
  position: relative;

  ${minBreakpointQuery.mlarge`
    margin-left: 55px;
  `}
`;

const StyledImage = styled(GatsbyImage)``;

const StyledImageDescription = styled.p`
  padding: 10px 0;
`;

const StyledArrow = styled(Svg)`
  display: block;
  position: absolute;
  top: -110px;
  left: 5%;
  z-index: 1;

  ${minBreakpointQuery.mlarge`
    top: -80px;
   `}

  ${minBreakpointQuery.xxlarge`
    left:15%;
   `}
`;

const StyledSlider = styled(Slider)`
  cursor: pointer;
  overflow: visible;

  .swiper-slide {
    max-width: 840px;
    width: 100%;
  }

  .swiper-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 15px 0;

    .swiper-pagination-bullet {
      margin: 0 13px;
    }

    .swiper-pagination-bullet-active {
      position: relative;
      width: 40px;
      height: 38px;
      background-color: ${brandColours.tertiary};
      clip-path: polygon(50% 0, 100% 38%, 81% 100%, 19% 100%, 0 38%);
      transform: rotate(-20deg);
      border-radius: unset;
    }
  }
`;

const StyledContainer = styled(Container)`
  ${minBreakpointQuery.medium`
    max-width: ${({ text }) => (text ? '1000px' : '890px')};
    margin-left: unset
  `}

  ${minBreakpointQuery.mlarge`
    margin-left: auto
  `}
`;

const StyledAnnotation = styled(Svg)`
  display: none;

  ${minBreakpointQuery.xlarge`
    display: block;
    position: absolute;
    right: -21%;
    top: 30%;
   `}

  ${minBreakpointQuery.xxlarge`
    right: -25%;
   `}
`;
const StyledSliderWrapper = styled.div`
  overflow: hidden;
  width: 100%;
`;

const StyledSlide = styled.div``;

const StyledText = styled.p`
  ${fontSize(28)}
  font-weight: unset;
  padding-top: 50px;

  ${minBreakpointQuery.small`
    ${fontSize(32)}
  `}

  ${minBreakpointQuery.smedium`
    ${fontSize(36)}
  `}

  ${minBreakpointQuery.mlarge`
    ${fontSize(42)}
  `}
`;
const StyledHeading = styled.h2`
  margin-top: 30px;
  ${fontSize(20)}
  font-weight: ${fontWeights.light};

  ${minBreakpointQuery.small`
    ${fontSize(22)}
  `}

  ${minBreakpointQuery.smedium`
    ${fontSize(24)}
  `}

  ${minBreakpointQuery.mlarge`
    margin-top: 0;
    ${fontSize(26)}
  `}
`;

const StyledReverseArrow = styled(Svg)`
  display: block;
  position: absolute;
  bottom: -110px;
  right: 5%;
  z-index: 1;
  transform: scaleX(-1);

  ${minBreakpointQuery.mlarge`
    bottom: -80px;
   `}

  ${minBreakpointQuery.xxlarge`
    right:15%;
   `}
`;

const ImageGallery = ({ header, subHeader, gallerySlides }) => {
  const sliderOptions = {
    modules: [Pagination],
    spaceBetween: 50,
    slidesPerView: 1,
    pagination: { clickable: true },
  };
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => children,
    },
  };
  return (
    <StyledImageGallery>
      <StyledArrow image={arrow} />
      <StyledContainer narrow={true} text={true}>
        <StyledContext>
          <StyledHeading>{renderRichText(subHeader, options)}</StyledHeading>
          <StyledText>
            <HighlightText>{header}</HighlightText>
          </StyledText>
          <StyledAnnotation image={annotation} />
        </StyledContext>
      </StyledContainer>
      <StyledSliderWrapper>
        <StyledContainer narrow={true}>
          <StyledSlider {...sliderOptions}>
            {gallerySlides.map(({ slideDescription, slideImage }, id) => (
              <StyledSlide key={id}>
                <StyledImage
                  image={slideImage.gatsbyImageData}
                  alt={slideImage.title}
                />
                <StyledImageDescription>
                  {slideDescription}
                </StyledImageDescription>
              </StyledSlide>
            ))}
          </StyledSlider>
        </StyledContainer>
      </StyledSliderWrapper>
      <StyledReverseArrow image={arrow} />
    </StyledImageGallery>
  );
};

export default ImageGallery;
