import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  brandColours,
  breakpointSizes,
  fluidFontSize,
  fontSize,
  fontWeights,
  maxBreakpointQuery,
  minBreakpointQuery,
  sectionPaddings,
} from '../styles';
import { Svg, Container, HighlightText, Slider, RenderedText } from './ui';
import circle from '../images/circle.inline.svg';
import pentagon from '../images/pentagon-large.inline.svg';
import square from '../images/square.inline.svg';
import trapezium from '../images/trapezium.inline.svg';
import triangle from '../images/big-triangle.inline.svg';
import paperGrid from '../images/paper-tear.png';

const StyledFeaturedList = styled.section`
  position: relative;
  background-color: ${brandColours.quinary};
  ${sectionPaddings('120px', '120px')}
  overflow: hidden;
`;

const StyledContainer = styled(Container)`
  position: relative;
`;

const StyledHeader = styled.header`
  ${minBreakpointQuery.smedium`
    max-width: 760px;
    margin: auto;
  `}
`;

const StyledHeading = styled.h2`
  ${fluidFontSize(
    '28px',
    '42px',
    breakpointSizes.tsmall,
    breakpointSizes.medium
  )}
  margin-bottom: 30px;
`;

const StyledText = styled.p`
  ${fontSize(20)}

  ${minBreakpointQuery.small`
    ${fontSize(22)}
  `}

  ${minBreakpointQuery.medium`
    ${fontSize(24)}
  `}

  ${minBreakpointQuery.large`
    ${fontSize(26)}
  `}
`;

const StyledSlider = styled(Slider)`
  overflow: visible;
  .swiper-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 30px;
    margin-left: 15px;
    margin-bottom: 15px;
    gap: 15px;
    max-width: fit-content;
    overflow: visible !important;

    .swiper-slide {
      min-width: 275px;
      max-width: 275px;

      ${minBreakpointQuery.smedium`
        min-width: 250px;
        max-width: 250px;
      `}
    }

    ${minBreakpointQuery.smedium`
        grid-template-columns: repeat(4, 1fr);
        margin-left: 0px;
        margin-top: 40px;
        margin-bottom: 40px;
    `}

    ${maxBreakpointQuery.smedium`
      height: 450px;
      min-width: max-content;

      .swiper-slide: nth-child(even) {
        grid-row-start: 2;
      } 
    `}
  }
`;

const StyledPrinciple = styled.div`
  position: relative;
  height: fit-content;
  transform: translate(-50%, -50%);
  width: fit-content;
  grid-row-start: ${({ id }) => (id % 2 === 0 ? '1' : '2')};

  top: ${({ id }) =>
    id === 0
      ? '50%'
      : id === 1
      ? '20%'
      : id === 2
      ? '50%'
      : id === 3
      ? '15%'
      : 'unset'};

  left: ${({ id }) =>
    id === 0
      ? '30%'
      : id === 1
      ? '85%'
      : id === 2
      ? '20%'
      : id === 3
      ? '90%'
      : 'unset'};

  ${minBreakpointQuery.smedium`
    grid-row-start: 1;
    left: 50%;
    top: ${({ id }) =>
      id === 0
        ? '50%'
        : id === 1
        ? '35%'
        : id === 2
        ? '60%'
        : id === 3
        ? '35%'
        : 'unset'};
  `}
`;

const StyledTextWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 80%;
`;

const StyledSubheading = styled.h3`
  ${fontSize(28)}

  ${minBreakpointQuery.medium`
    ${fontSize(32)}
  `}

  ${minBreakpointQuery.large`
    ${fontSize(36)}
  `}

  span {
    font-weight: ${fontWeights.light};
    margin-right: 10px;
  }
`;

const StyledSubText = styled.p`
  ${fontSize(16)}

  ${minBreakpointQuery.medium`
    ${fontSize(18)}
  `}

  ${minBreakpointQuery.large`
    ${fontSize(20)}
  `}
`;

const StyledShape = styled(Svg)``;

const StyledArticle = styled.article`
  display: flex;
  gap: 60px;

  ${maxBreakpointQuery.medium`
    flex-direction: column;
  `}
`;

const StyledImageWrapper = styled.div`
  position: relative;
  min-width: fit-content;
  margin-left: auto;
  margin-right: auto;
`;

const StyledBackground = styled.div`
  display: none;

  ${minBreakpointQuery.tiny`
    display:block;
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    position: absolute;
    left: -20px;
    width: 120%;
    height: calc(100% + 50px);
    background-size: 600px auto;
    background-repeat: no-repeat;
    background-position: 50% bottom;
    background-image: url(${paperGrid});
    transform: rotate(90deg);
  `};
`;

const StyledImage = styled(GatsbyImage)`
  max-width: 325px;
  min-height: 400px;
  flex-shrink: 0;
  height: 100%;
  transform: rotate(-1.5deg);
`;

const StyledTrapezium = styled(Svg)`
  position: absolute;
  top: 25%;
  left: -50px;
  fill: ${brandColours.primary};
`;

const StyledDescription = styled.p`
  white-space: break-spaces;
  ${fontSize(18)}
`;

const FeaturedList = ({ heading, description, image, list, text }) => {
  const ShapeNames = [square, triangle, circle, pentagon];
  const sliderOptions = {
    slidesPerView: 2,
  };
  return (
    <StyledFeaturedList>
      <StyledContainer narrow={true}>
        <StyledHeader>
          <StyledHeading>
            <HighlightText offset={0}>{heading}</HighlightText>
          </StyledHeading>
          <StyledText>{RenderedText(text)}</StyledText>
        </StyledHeader>

        <StyledSlider {...sliderOptions}>
          {list.map(({ heading, text }, id) => {
            return (
              <StyledPrinciple id={id}>
                <StyledTextWrapper>
                  <StyledSubheading>
                    <span>{id + 1}</span>
                    {heading}
                  </StyledSubheading>
                  <StyledSubText>{text}</StyledSubText>
                </StyledTextWrapper>
                <StyledShape image={ShapeNames[id]} />
              </StyledPrinciple>
            );
          })}
        </StyledSlider>

        <StyledArticle>
          <StyledImageWrapper>
            <StyledBackground></StyledBackground>
            <StyledImage image={image.gatsbyImageData} />
            <StyledTrapezium image={trapezium} />
          </StyledImageWrapper>
          <StyledDescription>{RenderedText(description)}</StyledDescription>
        </StyledArticle>
      </StyledContainer>
    </StyledFeaturedList>
  );
};
export default FeaturedList;
